@import "fontSize.css";

.mainbg {
    background-color: #F5F5F5;
    width: 100%;
    display: flex;
    justify-content: center;
}

.form {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: white;
    margin: auto;
    max-width: 500px;
    width: 100%;
    padding: 100px 20px;
    background: #fff;
    box-shadow: 0px 2px 8px rgba(80, 121, 178, 0.1);
}

.login_logo{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
}

.emailinfo {
    display: flex;
    padding-left: 10px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
    border-radius: 8px;
}

.passwordinfo {
    margin-top: 20px;
    padding-right: 10px;
}

.emailinfo input[type="text"], .emailinfo input[type="password"] {
    border: none;
    width: 100%;
    padding: 12px 10px;
    border-radius: 8px;
}

.formdata h2 {
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: #4C5966;
}

.formdata p {
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #5079B3;
    margin-left: 0px
}
.checkbox-label{
    margin-left: 10px;
}
.eye-icon-container {
    display: flex;
    align-items: center;
    margin-top: 3px; /* Adjust the margin-top as needed */
    margin-left: 1px; /* Adjust the margin-top as needed */

}

.eye-icon {
    cursor: pointer;
}
.formforget {
    margin-top: 20px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-wrap: wrap;
}

.formforget p {
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #003580;
}

.formforget label {
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}
.formbtn {
    margin-top: 20px;
}
.postbtn button{
    border-radius: 50px;
    width: 150px;
    padding: 8px 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    color: white;
    border: 1px solid #003580;
    background-color: #003580;
}
.formbtn button {
    width: 100%;
    padding: 10px 0px;
    background: #003580;
    border: 1px solid #003580;
    border-radius: 4px;
    color: #FFFFFF;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

.another-btn {
    width: 100%;
    padding: 10px 0px;
    background: #e54545;
    border: 1px solid #e54545;
    border-radius: 4px;
    color: #FFFFFF;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}
.full-width{
    width: 100%;
}

.formbtn button a {
    text-decoration: none;
    color: white;
}

.formbtn p {
    margin-top: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #4C5966;
}

.formbtn span a, .formforget p a {
    color: #003580;
    text-decoration: none;
}


@media (max-width: 768px){

    .form{
        padding: 0px 1rem;
        margin: auto;
    }


}