@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600&family=Yantramanav:wght@100;300;400;500;700&display=swap');

@import "fontSize.css";
.container-fluid{
    padding: 0px !important;

}

.mainpage1aljobv2{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
}


.postcol1aljobv2{
/* margin-left: 25%; */
width: 100%;
padding-top:120px ;
/* padding-bottom:30px ; */
padding-left: 60px;
padding-right: 60px;

}


/*------------------------------------- 1st  Column --------------- */

.postanalyticsPSaljobv2{
    text-align: center;
    background: #003580;
    color: white;
    padding: 10px 0px;
    border-radius: 8px;
    border: 1px solid #003580;
    /* margin-bottom: 17px; */
    display: flex;
    justify-content: space-between;
    padding: 12px 20px;
    width: 100%;
}
.postanalyticsPSaljobv2 p{
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.jobappliv2{
    text-align: center;
}

.resumesearch1aljob{
    width: 45%;
}
.joballsearchv2{
    width: 30%;
}
/*------------------------------------ Second Column ------------------------------ */

button .btn{
    border-radius: none !important;
}

hr.rescardinfolinealjob {
    margin: 0px;
    border-top: 1px solid #003580;
}

.main1aljob{
    background-color: #F5F5F5;
} 
.search_datealjob {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
    border-radius: 8px;
    padding: 10px 16px;
}
.alljoblistv2 table{
    width: 100%;
}
.alljoblistv2 th:nth-child(1){
    border-radius: 8px 0px 0px 0px;
}
.alljoblistv2 th:nth-child(7){
    border-radius: 0px 8px 0px 0px;
    text-align: center;
}
.alljoblistv2 tr:nth-child(1){
    border: none;
    border-radius:8px 8px !important;
}
.alljoblistv2 tr:nth-child(8){
border-radius:8px ;
}
.alljoblstrowv2 tr{
    border-radius:8px ;
}
.alljoblstrowv2 td:nth-child(1){
  border-radius:8px ;  
}
.alljoblistv2 th{
    background: #003580;
    color: white;
    text-align: center;
}
.alljoblistv2 tr{
border: 1px solid #d8d8d8; 
 
}
.editjobsiconv2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 10px;
}
.editjobsiconv2 img{
    width: 25px;
}
.editjobsiconv2 i:nth-child(3){
    color: #E54545;
}
.alljoblistv2 th:nth-child(4){
    text-align: center;
}
.editjobsiconv2 i{
    color:#003580 ;
}
.alljoblistv2 td{
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 15px; 
    text-align: center;
}
.alljoblistv2 th{
    /*text-transform: uppercase;*/
    font-style: normal;
    font-weight: 500;
    padding: 15px;
}
.alljoblistv2 tr:nth-child(odd) {
background-color: #f3f3f3;
}
.jobstatusv2{
    border: none;
    background: unset;
    padding: 5px 10px;
}
/*------------------------------------- 3rd  Column --------------- */

/*---------------------------Favourite Resume ------------- */
.favouritealjob{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
}
.resumealjob{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.resumealjob p {
    font-style: normal;
    color:#828E99;
    font-weight: 400;
}
.resumealjob p a{
    color:#828E99;
}

.fvtresumaljob{
    display: flex;
    align-items: center;
}
.rsfvtresumtxtaljob {
    margin-left: 15px;
}
.rsfvtresumtxtaljob h6{
    color: #4C5966;
    margin-top: 10px;
    margin-bottom: 0px;
    line-height: 20px;
}
.rsfvtresumtxtaljob h6 a{
    color: #4C5966;
}
.rsfvtresumtxtaljob p{

    font-weight: 400;
    margin-bottom: 0px;
    margin-left: 0px;
    line-height: 20px;
    color: #828E99;
}
.rsfvtresumtxtaljob p a{
    color: #828E99;
}

.dropbtn {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}
.topjoballv2{
    display: flex;
    align-items: center;
    column-gap: 15px;
    margin-bottom: 25px;
}
.alljobtable-search{
    padding: 40px 10px;
}
.alljobfilterbn i{
    border: 1px solid #003580;
    padding: 10px;
    border-radius: 8px;
    color: #003580;

}
.dropdown-contentaljob {
    display: none;
    position: absolute;
    z-index: 1;
    background-color: #f44336;
    right: 0;
}

.dropdown-contentaljob button {

    background-color: #f44336;
    color: #333;
    padding: 8px 12px;
    border: none;
    outline: none;
    cursor: pointer;
}

.dropdown .dropdown-contentaljob {
    display: block;
    background-color: #f44336;
}
.custom_datealjob{
    display: flex;
    flex-direction: row;
    gap: 7px;
    margin-top: 10px;
}

@media (min-width:1200px) and (max-width:1400px){
    .postcol1aljobv2{
    width: 100%;
    padding-top:120px ;
    padding-left: 60px;
    padding-right: 60px;

    }

}