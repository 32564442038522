@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600&family=Yantramanav:wght@100;300;400;500;700&display=swap');
@import "variables.css";
@import "fontSize.css";


.container-fluid{
    padding: 0px !important;
}

.mainpage1{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
}
.highlight {
    background-color: var(--highlight-color);
    /*font-weight: bold;*/
}
.resumecol1{

    width: 25%;
    background-color: var(--sidebar-bg);
    padding: 30px 30px;
    position: fixed;
    overflow-y: scroll;
    top: 75px;
    bottom: 0;
}
.postcol1{
    margin-left: 25%;
    width: 50%;
    height: 100vh;
    padding-top:100px ;
    padding-left: 60px;
    padding-right: 60px;
}
.JSleftheading h2{
    color: var(--sidebar-heading-color) !important;
}
.dashboardcol1{
    width: 25%;
    background-color: var(--sidebar-bg);
    padding: 30px 30px;
    position: fixed;
     overflow-y: auto;
    top: 75px;
    bottom: 0;
    right: 0;
}
.dashboardcol1::-webkit-scrollbar {
    width: 0;
}

.resumesercard-main {
    margin-bottom: 40px;
    margin-top: 10px;
    overflow-y: auto; /* Change to 'scroll' if you always want to show the scrollbar */
}
.highlight {
    background-color: var(--highlight-color);
    /*color: var(--font-black-color);*/
    font-weight: bold;


}
.no-results-container{
    margin-top: 20px !important;
}
.resumesercard-main :nth-child(1) {
    margin-top: 0px;
}


.blur{
    -webkit-filter: blur(5px);
}

.divider{
    flex-wrap: wrap;
    display: flex;
}

.add-line {
    border-bottom: 1px solid grey;
    width: 100%;
}

.full-width-p{
    width: max-content;
}

.work-experience div input[type="checkbox"] {
    margin-right: 10px;
}
.keyword-match {
    padding: 4px 8px; /* Adjust the padding values as needed */
    margin-right: 4px; /* Add space between each keyword match */
    border-radius: 4px;
    color: white;
    font-weight: bold;
    /* Add any other desired styles */
}


/*------------------------------------- 1st  Column --------------- */
.resumeupdate select{
    width: 100%;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
    border-radius: 5px;
    padding: 6px 14px;
}
.round-image {
    width: 56px;
    height: 56px;
    border-radius: 50%;
}

.work-authoriz{
    margin-top: 30px;
}
.work-authorizbord{
    border: 1px solid #c2c2c2;
    background: white;
    border-radius: 8px;
    padding: 10px 10px;
}
.work-experience{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 2px;
}
.work-experience p{
    color: #003580;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
}
.resumesearch1{
    width: 45%;
}
/*------------------------------------ Second Column ------------------------------ */
.searresume1{
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
    border-radius: 8px;
    padding: 0.4rem;
    border: 1px solid #003580;
}
.searchcard2{
    margin-top: 15px;
    margin-right: 5px;
}
.searchcard2:last-child{
    margin-bottom: 50px;
}

.resumesearch-bar select{
    border: none !important;
    z-index: 333;

}
.searchtitle,.searchcityco{
    width: 100%;
}

.resumesearch-bar input[type="text"]{
    border: 1px solid hsl(0, 0%, 80%);
    padding: 6px 10px;
    border-radius: 4px;
}

.input-container {
    position: relative;
}

/*.clear-button {*/
/*    position: absolute;*/
/*    top: 45%;*/
/*    right: 10px;*/
/*    transform: translateY(-50%);*/
/*    background: none;*/
/*    border: none;*/
/*    cursor: pointer;*/
/*    color: #999999;*/
/*    padding: 0;*/
/*}*/

.clear-button {
    position: absolute;
    top: 45%;
    right: 10px;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    color: #999999;
    padding: 0;
    font-size: 2.5rem !important;
}

.clear-button:hover {
    color: #000000;
}


.resumesearch-bar ::placeholder{
    font-weight: 400;

}
.resumesearch-bar  .btn{
    border: 1px solid #003580 !important;
    padding: 7px 20px;
    margin-top: 35px;
    background: #003580;
    color: white;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}
.resumesearch-bar{
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
}
button .btn{
    border-radius: none !important;
}
.resumesearch-bar  .red-btn{
    background: #E54545 !important;
    border: unset !important;
    color: white !important;
}
.info1-img{
    display: flex;
    column-gap: 10px;
}

.total_resume {
    display: flex;
    flex-direction: row;
    padding-top: 0.5rem;

}

.total_resume h2:nth-child(1){
    font-style: normal;
    line-height: 0px;
    color: black
}
.total_resume h2:nth-child(2){
    color: #E54545 ;
    font-style: normal;
    line-height: 0px;
}
.searresume1, .info1,.info2, .info3 , .info4{
    display: flex;
}
.info2{
    flex-wrap: wrap;
}

.info3 , .info4{
    justify-content: space-between;
}

 .info4, .info5{
    padding-top: 8px;
 }
.searresume1{
    align-items: flex-start;
}
.info5{
    display: flex;
    align-items: baseline;
}
.searresume1 p{
    margin: 0px;
}
.info1{
    justify-content: space-between;
    align-items: center;
}
.info1 p ,.info2 p, .info3 p ,.info4 p,.info5 p{
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    padding: 4px 0px;
}
.info1 p ,.info2 p{
    color: #003580;
}
.info3 p ,.info4 p,.info5 p{
    color: #000000;
}
.info-span{
    color: #17181A;
    margin-left: 0.2rem;
    /*margin-right: 10px;*/
    font-weight: 400;
}
hr.rescardinfoline {
    margin: 0px;
    border-top: 1px solid #003580;
}
.skillspan{
    font-weight: 400;
    color: #17181A;
}
.info1 p {
    font-weight: 600 !important;
}

.maininfo{
    margin-left: 18px;
    width: 100%;
}

.search_date {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
    border-radius: 8px;
    padding: 10px 16px;
}
/*------------------------------------- 3rd  Column --------------- */

/*---------------------------Favourite Resume ------------- */
.favourite{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
}
.resume{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.resume p {
    font-style: normal;
    color: var(--sidebar-text-color);
    font-weight: 400;
}
.resume p a{
    color: #1c1c1c;
}
.resume h2,.resumeupdate h2 , .postcol1 h2{
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #17181A;
}
.resume h2,.resumeupdate h2 {
    margin-bottom: 20px;
    margin-top: 12px;
}
.postcol1 h2{
    margin-bottom: 10px;
    margin-top: 10px;
    text-transform: capitalize;
}
.postanalyticsRS{
    text-align: center;
    background: #003580;
    color: white;
    padding: 10px 0px;
    border-radius: 8px;
    border: 1px solid #003580;
    margin-bottom: 0.3rem;
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
}
.postanalyticsRS p{
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: white;
}
.fvtresum{
    display: flex;
    align-items: center;
}
.rsfvtresumtxt {
    margin-left: 15px;
}
.rsfvtresumtxt h6{

    color: var(--sidebar-heading-color);
    margin-top: 10px;
    margin-bottom: 0px;
    line-height: 20px;
}
.rsfvtresumtxt h6 a{
    color: #1c1c1c;
}
.rsfvtresumtxt p{
    font-weight: 400;
    margin-bottom: 0px;
    margin-left: 0px;
    line-height: 20px;
    color: #1c1c1c;
}
.rsfvtresumtxt p a{
    color: var(--sidebar-text-color);
}

.basic_text{
    color: var(--sidebar-text-color);
}


.dropbtn {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.dropdown-content {
    display: none;
    position: absolute;
    z-index: 1;
    backgroundColor: "#f44336";
    right: 0;
}

.dropdown-content button {

    backgroundColor: "#f44336";
    color: #333;
    padding: 8px 12px;
    border: none;
    outline: none;
    cursor: pointer;
}

.dropdown .dropdown-content {
    display: block;
    backgroundColor: #f44336;
}
.custom_date{
    display: flex;
    flex-direction: row;
    gap: 7px;
    margin-top: 10px;
}
.title-search{
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index:222;
}
.city-search{
   display: flex;
    flex-direction: column;
    width: 80%;
    padding-top: 0.3rem;
}
.searchbtnRS{
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.city-search label,.title-search label{
    padding-bottom: 0.3rem;
}
.location_container{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 0.5rem;
}

.sidebarFilter{
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}



@media (min-width:1024px) and (max-width:1400px){

    .resumecol1{
    width: 25%;
    padding: 8px;
    }
    .postcol1{
        width: 50%;
        padding-top:77px;
       padding-left: 20px;
       padding-right: 20px;
       margin: auto;
    }
    .dashboardcol1{
  
         width: 25%;
        padding: 8px;
    }
    .city-search{
        width: 66%;
    }
    .resumesercard-main{
        height: 300px;
    }

}

@media (max-width:1024px){
    .resumecol1,.dashboardcol1{
        display: none;
    }
    .postcol1{
        width: 100%;
        /*margin-left: 0px;*/
        margin: 0;
    }
    .city-search,.searchbtnRS{
        width: 100%;
    }
}
@media (max-width:520px){

    .postcol1{
        padding-top:73px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .maininfo{
        margin-left: 0px;
    }
    .searresume1 {
    flex-direction: column;
}
.postanalyticsRS{
    /*flex-direction: column;*/
    row-gap: 0.2rem;
    padding: 0.4rem;
}
.location_container{
    flex-direction: column;
}
    .resumesearch-bar .btn{
    margin-top: 0;
}
}