@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600&family=Yantramanav:wght@100;300;400;500;700&display=swap');
@import "variables.css";
@import "fontSize.css";

* {
    padding: 0px;
    margin: 0px;
}

.maindashbordv1{
 background: #F5F6F7; 
 padding-top: 73px;
}

.employer_logo_img{
    width: 56px;
    height: 56px;
    border-radius: 50%;
}
hr.maindashv1line{
    border-top: 1px solid white;
    opacity: unset;
}
.dashjobiconv1 p {

    font-style: normal;
    color: var(--sidebar-text-color);
    font-weight: 400;
    margin: 0px;
}
.resumecolv1 h2{

    color: var(--sidebar-heading-color);
}

.resumev1 {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.resumev1 p {
    font-style: normal;
    color:  var(--sidebar-text-color);
    font-weight: 400;
    cursor: pointer;
}

.resumev1 p a ,.analyticsv1 h2 a{
     color: var(--sidebar-heading-color);
}

.dashjobiconv1 p a {

    color: var(--sidebar-text-color);
}

.analyticsv1 h2 {
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: var(--sidebar-heading-color);
    margin-bottom: 0px;
}

p{
    margin-left: 0px !important;
}

.mainpagev1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /*padding-top: 40px;*/
}

.resumecolv1 {
    max-width: 350px;
    width: 100%;
    padding-top: 20px;
    padding-right: 40px;
    padding-bottom: 20px;
    padding-left: 20px;
    border-radius: 8px;
    overflow-y: auto;
    height: 85vh;
    background: var(--sidebar-bg);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-top: 8px solid var(--sidebar-border-color);
}

.postcolv1 {
    max-width: 530px;
    width: 100%;

}

.dashboardcolv1 {
    max-width: 350px;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 40px;
    border-radius: 8px;
    overflow-y: auto;
    height: 85vh;
    background: var(--sidebar-bg);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-top: 8px solid var(--sidebar-border-color);
}
.js-dashboard-posts-section::-webkit-scrollbar {
    width: 0;
}
.resumecolv1::-webkit-scrollbar {
    width: 0;
}
.dashboardcolv1::-webkit-scrollbar {
    width: 0;
}
.sidebar_content .modal-content{
    background-color: var(--sidebar-bg);
}
.sidebar_content .modal-header{
    color: var(--sidebar-heading-color);
}

/*------------------------------------- First Column --------------- */
.favouritev1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
}

.fvtresumv1, .activejobv1 {
    display: flex;
    align-items: center;
    column-gap: 16px;
}
.EDcompanytxt{
    color: black;
    cursor: pointer;
}
.EDtext{
    color: var(--sidebar-text-color);
    margin-bottom: 0.5rem;
}
.EDtext span{
    color: var(--sidebar-heading-color);
    font-weight: 600;
}
.fvtresumtxtv1 {
    margin-left: 15px;
}

.fvtresumtxtv1 h6, .activejobtxtv1 h6 {
    color: var(--sidebar-heading-color);
    margin-top: 10px;
    margin-bottom: 0px;
    line-height: 20px;
    cursor: pointer;
}

.fvtresumtxtv1 h6 a, .activejobtxtv1 h6 a {

    color: var(--sidebar-heading-color);
}

.fvtresumtxtv1 p, .activejobtxtv1 p, .posttxtv1 p {
    font-weight: 400;
    margin-bottom: 0px;
    line-height: 20px;
    color:  var(--sidebar-text-color);
}


.postsharev1 p {
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    color: #828E99;
    padding-left: 11px;
}

.postsharev1 p a {
    color: #828E99;
}
.activejobtxtv1
{
    margin-right: 2px !important;
}
.fvtresumtxtv1 p a, .activejobtxtv1 p a, .posttxtv1 p a {
    color: #ffffff;
}

.creatpostv1 p {
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 0px;
    color: #828E99;
}

.posttxtv1 p:nth-child(1) {
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    color: #242833;
}

.activejobv1 {
    padding-top: 20px;
    width: 100%;
}


.creatpostv1 {
    background: white;
    padding: 18px 18px;
    box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
    border-radius: 8px;
}

.showpostv1 {
    background: white;
    padding: 18px 18px;
    box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
    border-radius: 8px;
    margin-top: 24px;
}

.mainpostv1 img {
    width: 100%;
    margin: auto;
}

.mainpostv1 ul {
    padding-top: 10px;
    padding-left: 20px;
}

.mainpostv1 p, .mainpostv1 ul li {
    font-style: normal;
    font-weight: 400;
    text-align: justify;
    line-height: 23px;
    margin-bottom: 0px;
    color: #4a4a4a;
}

.postprofilev1 {
    display: flex;
    align-items: center;
}

.posttxtv1 {
    padding-left: 13px;
}

.postdotsv1 {
    display: flex;
    justify-content: end;
    align-items: center;
}

.ed-right
{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.postsharev1 {
    display: flex;
    align-items: baseline;
    margin-right: 30px;
}

.postsharev1 img {
    width: 20px;
}

#postcreatv1 {
    display: none;
}

.posticonsv1 {
    display: flex;
    padding-top: 25px !important;

}

/*---------------------------------First Column ------------------------*/

.dashjobiconv1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0.5rem;
}

.dashjobiconv1 p:nth-child(2) {
    color: black;
    background-color: #ffffff;
    border: 1px solid black;
    text-align: center;
    line-height: 23px;
    padding: 5px;
    font-weight: 700;
    min-width: 36px;
    max-width: 48px;
    height: 36px;
    border-radius: 8px;
}

.dashjobiconv1 p:nth-child(2) a {
    color: black;
}

/*------------------------------------ Second Column ------------------------------ */
textarea {
    width: 100%;
    border: none;
}


.postmaterialv1 {
    display: flex;
}

.postmaterialv1 img {
    padding-right: 12px;
    width: 40px;
}

.postpublishv1 p {
    margin-right: 13px;
}

.postpublishv1 button {
    padding: 4px 20px;
    background: #003580;
    color: white;
    border: 1px solid #003580;
    border-radius: 4px;
}

/* ----------------------------------------------------------------- */
/*                         Post A JOb                               */
/* ----------------------------------------------------------------- */

.postcontainerv1 {
    background-color: white;
    max-width: 60%;
    margin: auto;
    padding: 20px 30px;

}

h5 {
    position: relative;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: #17181A;
}

.fieldsv1 {
    margin-top: 20px;
}

.formheadingv1 h5:after {
    /*content: "";*/
    display: block;
    max-width: 74%;
    width: 100%;
    height: 1px;
    background: #dbdbdb;
    left: 170px;
    top: 60%;
    position: absolute;
}

label {
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #4C5966;
}
.rsfvtresumtxtv1 h6 {
    color: var(--sidebar-heading-color);
    margin-top: 10px;
    margin-bottom: 0px;
    line-height: 20px;
}
.rsfvtresumtxtv1 h6 a{
   color: #ffffff; 
}

.rsfvtresumtxtv1 p {
    font-weight: 400;
    margin-bottom: 0px;
    margin-left: 0px;
    line-height: 20px;
    color: var(--sidebar-text-color);
}
.rsfvtresumtxtv1 {
    margin-left: 15px;
}

#posttype-catv1{
    padding: 4px 10px;
    background: #003580;
    margin-right: 15px;
    color: white;
    border: 1px solid #003580;
    border-radius: 4px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.emp_sidebar{
    background: white;
    padding: 10px;
    box-shadow: 0px -4px 10px rgba(115, 153, 191, 0.2);
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1000;
    /*border-top: 0.1rem solid lightgray;*/

}
.sidebar_content{
    color: #888;
}
.sidebar_content:active{
   border-bottom: 1px solid #003580;
}

.modal-body{
    padding: 1rem !important;
}

h1,h2,h3,h4,h5{
    font-family: var(--font-family)  !important;
    font-weight: bold  !important;
}
span{
    font-family: var(--font-family)  !important;

}
p{
    font-family: var(--font-family)  !important;

}
label{
    font-weight: bold !important;
}
input[type=text] {
    font-family: var(--font-family)  !important;

}
.social-media-font {
    font-family: var(--social-media-font-family) !important;
    margin-bottom: 10px;

}
.social-media-font-p {
    font-family: var(--social-media-font-family) !important;
    font-weight: lighter;
    font-size: 15px;

}
button{
    font-family: var(--font-family)  !important;
}
.text-success{
    font-family: var(--font-family)  !important;
}
.text-danger{
    font-family: var(--font-family)  !important;
}

body {
    font-family: var(--font-family)  !important;
}
.generic-image-size{
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
@media (min-width: 1200px) and (max-width: 1400px) {

    .resumecolv1 {
        max-width: 280px;
        padding-right: 20px;
    }

    .postcolv1 {
        max-width: 520px;
    }

    .dashboardcolv1 {
        max-width: 280px;
        padding-left: 20px;
    }
}
@media (max-width: 1024px){
    .resumecolv1,
    .dashboardcolv1{
        display: none;
    }
    .postcol-rd{
        max-width: 100%;
        margin: 0;
    }
    .postcol{
    max-width: 100%;
    }


}

