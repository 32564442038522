@import "fontSize.css";
.resumeContent{
    background-color: #ededed;
    border-radius: 8px;
    width: 100%;
    margin-bottom: 3rem;
}

.resumeContent_scroll{
    height: calc(100vh - 175px);
    overflow-y: scroll;
    padding: 0.4rem;
}
.resume_content:first-child{
    border-radius: 0.3rem 0.5rem 0 0;
}
.resume_content:last-child{
    border-radius: 0 0 0.3rem 0.5rem;
    border: none;
    margin-bottom: 0.4rem;
}

.resume_content{
    background-color: #fff;
    border-bottom: 1px solid #ededed;
}
.resume_content:hover{
    background-color: #e9ecef;
    color: #1e2125;
    cursor: pointer;
}
.logo{
    width: 56px;
    height: 56px;
    border-radius: 50%;
}
.resume_content_text{
    color: #888;
}

.rightContent_scroll::-webkit-scrollbar {
    width: 0;
}