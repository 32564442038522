@import "fontSize.css";
.mainpostjob {
    padding-top: 120px;
    background: #F5F6F7;
}
.postjobcontainer{
max-width: 60%;
background-color: white;
box-shadow: 0 8px 24px #00000012;
    border-radius: 8px;
     margin: auto;
}
.postcontainer2 {
    background-color: #fff;
    padding: 40px 80px;

}
.EDJhead h2{
    line-height: 24px !important;
    font-weight: 500;
    margin-bottom: 0px !important;
}
.textdesc li:hover
{
    color:black !important;
    background-color: #fff !important;
}
.CRjobpreview li:hover
{
    color:black !important;
    background-color: #fff !important;
}

.postcontainer2 h2 {
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: #17181A;
}

.fields {
    margin-top: 20px;
}

.formheading {
    display: flex;
    align-items: self-end;
    margin-top: 20px;
}
.textdesc {
    margin-top: 20px;

}
.toggle-content > input + label {
    background-color: #e5e5e5 !important;
    justify-content: center !important;

}
.textdesc textarea {
    width: 100%;
    height: 250px;
    padding: 10px;
    background: #F5F6FA;
    box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
    border-radius: 8px;
    border: none;
}

.formheading h5 {
    margin-bottom: 0px;
    padding-left: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: #17181A;
}

.jobfield {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;

}

.jobfield input[type=text] {
    background: #e1e1e1;
}

input[type=text] {
    width: 100%;
    padding: 8px 16px;
    border: 1px solid rgba(46, 46, 46, 0.5);
    box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
    border-radius: 8px;

}

.job-id, .job-date {
    width: 48%;
}

label {
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #4C5966;
}

.job-title, .job-location, .job-type, .job-textterm, .job-worktype, .bonus-type, .job-year {
    margin-top: 24px;
}
.hr-style2{
    height: 1px;
    margin-left: 5%;
    width: 95%;
    /*margin-top: 35px;*/
    /*margin-bottom: 35px;*/
}
.location-change{
    display: flex;
    justify-content: space-between;
}
.pj-checkbox-text {
    margin-left: 8px;
}

.job-type label, .work-type label, .relocation label, .compension-type label, .bonus-type label, .job-year label,
.job-id label, .job-date label,.certi-type label,.job-title label,.interview-rounds label,.text-term label,.company label {
    padding-bottom: 10px;
}

.job-nature button {
    width: 30%;
    background: #F5F6FA;
    border: none;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #17181A;
}

.job-textterm {
    display: flex;
    justify-content: space-between;
}

.job-textterm select, .job-year select {
    width: 100%;
    padding: 10px 16px;
    border: 1px solid rgba(69, 69, 69, 0.5);
    box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
    border-radius: 8px;
}

.text-term, .company,.interview-rounds {
    width: 48%;
}
.zipcode{
    width: 100%;
}

.work-type, .relocation {
    width: 48%;

}

.job-worktype {
    display: flex;
    justify-content: space-between;
}

.compension-type, .hourly,.certi-type, .bouns-form, .bonus-percent {
    padding-top: 10px;
    width: 100%;

}

.bonus-type {
    display: flex;
    justify-content: space-between;
    align-items: end;
}

.hourly input[type=text] {
    margin-top: 32px;
}
.certi-type-input{
    padding: 9px 16px !important;
}
.interview-input{
     padding: 8px 16px !important;
}
.jobloc-input{
    
}

.crudbtn {
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
}
.hr {
    margin-top: 20px !important;
}
.crudbtn button {
    padding: 8px 25px;
    margin-top: 10px;
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    align-items: center;
    text-align: center;
}
.PJbtn button{
    border: 1px solid #003580 !important;
    background: #003580 !important;
    color: white !important; 
    padding: 8px 25px;
    margin-top: 10px;
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    align-items: center;
    text-align: center;
}
.PJbtn1 button {
    border: 1px solid #003580 !important;
    color: #003580!important;
    background: white;
    padding: 8px 25px;
    margin-top: 10px;
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    align-items: center;
    text-align: center;
}

/* ------------------------Description paragraph ---------------------- */
.textdesc {
    margin-top: 20px;
}

.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused),
.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
    height: 250px !important;
    border: 1px solid rgba(46, 46, 46, 0.5);
    box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar, .ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
    border-top-right-radius: 6px !important;
    border-top-left-radius: 6px !important;

}

.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused), .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable, .ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
    border-bottom-right-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
}


/*------------------------------------------- Segment control ------------------ */

.toggle-radio {
    display: flex !important;
    justify-content: space-between;
    border: 1px solid rgba(76, 89, 102, 0.15);
    border-radius: 6px;
    background: #F5F6FA;


}

.toggle-content {
    display: flex !important;
    justify-content: center;
    border: 1px solid rgba(76, 89, 102, 0.15);
    border-radius: 6px;
    background: #F5F6FA;
    width: 50%;
}
.toggle-content input[type='radio']{
    display: none;
}

.toggle-content label {
    border: none !important;
    width: 100%;
    text-align: center;
    padding: 7px 4px !important;
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #4C5966;

}
.labelcol{
    font-weight: 900;
}
hr.postjobline {
 
 border-top: 5px solid #003580 !important;
  border-radius: 5px;
  border-color: #003580 !important;
 margin-top:30px;
 margin-bottom: 30px;
}
.postanalyticsPS{
    text-align: center;
    background: #003580;
    color: white;
    padding: 10px 0px;
    border-radius: 8px;
    border: 1px solid #003580;
    margin-bottom: 17px;
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
}
.postanalyticsPS p{
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: white;
}

.toggle-content > input:checked + label {

    background: #003580 !important;
    color: white !important;
}

/* --------------------------------- Year of experience ----------------------------- */
.required-field {
    margin-left: 1px;
    color: #E54545 ;
}


.job-year {
    width: 48%;
}
/*-------------------------Mobile Screen ----------------------- */
@media (max-width:1143px) and (min-width:700px) {
.postjobcontainer{
        max-width: 95%;
        padding: 0;
}
 .toggle-content> input + label:before{
    height: 0px;
}
}
@media (max-width:699px) {
.postjobcontainer{
        max-width: 95%;

}
.postanalyticsPS{
    flex-direction: column;
    row-gap: 5px;
}
.jobfield, .job-textterm,.job-worktype{
    flex-direction: column;
}
.job-id, .job-date, .text-term,.job-year,.company, .interview-rounds,.relocation,.compension-type,.hourly{
    width: 100% !important;
}
.job-date,.company,.relocation{
    margin-top: 24px;
}
.crudbtn{
    flex-wrap: wrap;
}
.toggle-content > input + label:before{
    height: 0px;
}
}

@media (max-width:584px) {
    .postcontainer2{
        padding: 10px;
    }

}
@media (max-width:420px)  {
    .crudbtn button{
        padding: 8px !important;
    }
    .mainpostjob{
        padding-top: 83px;
        padding-bottom: 60px;
    }

}