
.splash-screen {
    /*position: fixed;*/
    width: 100%;
    height: 100vh;
    background-color: #fff; /* Choose your desired background color */
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    margin: 0 auto;
    box-shadow: 0px 2px 8px rgba(80, 121, 178, 0.1);
    max-width: 500px;
}

.splash-screen.visible {
    opacity: 1;
}



/* Add additional styles for your content */
.spinner {
    animation: spin 4s linear infinite, changeColor 4s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes changeColor {
    0% { color: #E54545; }
    100% { color: #fff; }

}

