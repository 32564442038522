@import "fontSize.css";

.main-RP{
    padding-top: 40px;
    background-color: #F5F6F7;
}
.postcontainer-RP{
    background-color: white;
    border-radius: 8px;
    max-width: 60%;
    margin: auto;
    padding: 40px 80px;
    margin-top: 90px !important;

}
.postcontainer-RP h2{
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: #17181A;
}

.fields{
    margin-top: 20px;
}
.formheading-RP,.expheading-RP{
    display: flex;
    align-items: self-end;
}
.formheading-RP{
    margin-top: 20px;
}
.formheading-RP h5 ,.expheading-RP h5{
    margin-bottom: 0px;
    padding-left: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: #17181A;
}
.jobfield-RP{
    display: flex;
    justify-content: space-between;
    margin-top: 24px;

}
.jobfield-RP input[type=text]{
    background: #e1e1e1;
}
input[type=text],.degree-PR,.major-PR,.major-PR ,.phone-PR,.companyname-PR,.hourly-PR,.startdate-PR,.enddate-PR,.min-salary-PR ,
.max-salary-PR{
    width: 100%;
    padding: 10px 16px;
    border: 1px solid rgba(46, 46, 46, 0.5);
    box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
    border-radius: 8px;

}
.job-id-PR , .job-date-PR,.text-term2-PR{
    width: 48%;
}
label{
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #4C5966;
}
.job-title-PR,.institute-PR ,.exp-pos,.resume-summery,.salary-PR ,.jobtype-PR, .job-location-PR, .job-type-PR,
.text-term-PR,.degreemajor-PR,.hourrecloc-PR,.expsection-PR,.dateselect-PR,.addbtn-PR,  .text-term2-PR, .interview-rounds-PR, .job-worktype-PR  ,.job-year-PR{
    margin-top: 24px;

}
.videores-PR{
    width: 48%;
}
.Profilepic-PR{
    width: 48%;
}
.expdtl-PR{
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 8px rgba(80, 121, 178, 0.1);
    border-radius: 8px;
    padding: 15px 20px;
    margin-top: 24px;
}

.job-type-PR label, .work-type-PR label ,.hourrecloc-PR label,  .job-year-PR label,.jobtype-PR label,.salary-PR label,
.job-id-PR label ,.job-date-PR label,.dateselect-PR label, .job-worktype-PR label, .resume-summery label{
    padding-bottom: 10px;
}
.job-nature button{
    width: 30%;
    background: #F5F6FA;
    border: none;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #17181A;
}

.work-authorization-PR,.zip-dress,.employement-Type-PR ,.industry-catagory,.skill-PR,.skill-level-PR, .job-year-PR select{
    width: 100%;
    padding: 10px 16px;
    border: 1px solid rgba(69, 69, 69, 0.5);
    box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
    border-radius: 8px;
}

.work-type-PR,.degreemajor,.relocation ,.startdate, .enddate,.company-PR,
.industry-PR ,.text-term-PR,.hourly-rates,.relocation  .text-term2-PR{
    width: 48%;

}
.relocation-PR,.hourrecloc-PR , .expdtl1-PR,.expdtl3-PR,.res-work ,.degreemajor-PR,.dateselect-PR,.expdtl2-PR{
    display: flex;
    justify-content: space-between;
}
.expdtl-img-PR{
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: space-around;
}
.expdtl-img-PR img{
    width: 28px;
}
.startdate, .enddate ,.hourly-rates,.relocation{
    display: flex;
    flex-direction: column;
}
.bonus-percent ,.annually-PR,.preferloc{
    width: 48% !important;
}
.bonus-type{
    display: flex;
    justify-content: space-between;
    align-items: end;
}
.expdtl2-PR p,.expdtl3-PR p,.expdtl1-PR p ,.expdtl4-PR p{
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #4C5966;
    margin-bottom: 0px;
    text-align: justify;
}

.expdtl1-PR h4{
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.adress-PR{
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
}
.city-PR , .adres-PR ,.zip-PR ,.phone-PR ,.home-PR,.office-PR{
    width: 48% !important;
}

.salary-range,.exp-pos,.profilesec{
    display: flex;
    justify-content: space-between;
}
.expsection-PR{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.addanother-PR button{
    background: #FFFFFF;
    border: 1px solid #60BF9F;
    box-shadow: 0px 2px 8px rgba(80, 121, 178, 0.1);
    border-radius: 50px;
    padding: 4px 12px;
    color: #60BF9F;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}
.addbtn-PR button{
    padding: 4px 20px;
    background: #60BF9F;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 8px rgba(80, 121, 178, 0.1);
    border-radius: 50px;
    color: #fff;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}
.min-salary ,.hourly-rates,.relocation,.res-position, .res-experience,.max-salary{
    width: 48%;
}

.crudbtn{
    display: flex;
    justify-content: space-between;
}
.crudbtn button{
    padding: 8px 25px;
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    align-items: center;
    text-align: center;
}

.toggle-radio > input + label{
    background-color: #e5e5e5 !important;
}

.resume-upload, .video-resume{
    border: 1px solid #828899;
    border-style: dashed;
    padding: 20px 30px;
    text-align: left;
    margin-top: 24px;
    text-align: center;
}
.resume-upload p{
    margin-bottom: 10px;
    margin-left: -190px;
}
.resume-summery textarea{
    width: 100%;
    height: 200px;
    background: #F5F6FA;
    padding: 5px;
    box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
    border-radius: 8px;
    border: none;
}
    .skilltodo-PR {
        display: flex;
        justify-content: flex-start;
        gap: 12px;
        flex-wrap: wrap;
    }
.skillpreview-PR {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 5px;
    margin-top: 5px;
}


.skillpreview-PR p {
    margin: 0;
    line-height: 25px;
    padding: 5px;
    text-align: center;
}

.res-experience select{
    width: 100%;
    padding: 10px 16px;
    border: 1px solid rgba(69, 69, 69, 0.5);
    box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
    border-radius: 8px;
}


/*------------------------------------------- Segment control ------------------ */

.toggle-radio{
    display: flex !important;
    justify-content: space-between;
    border: 1px solid rgba(76, 89, 102, 0.15);
    border-radius: 6px;
    background: #e5e5e5;

}
.toggle-radio label{
    border: none !important;
    width: 50%;
    text-align: center;
    /*padding-top: 12px !important;*/
    /*padding-bottom: 10px !important;*/
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #4C5966;

}
.toggle-radio > input:checked + label{
    background: #003580 !important;
}

/* --------------------------------- Year of experience ----------------------------- */


.job-year-PR{
    width: 100%;
}

/* ------------------------Video ---------------- */

