@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600&family=Yantramanav:wght@100;300;400;500;700&display=swap");
@import "fontSize.css";

.maindashbord {
  background: #f5f6f7;
  padding-top: 85px;
}
.dashjobicon p {
  font-style: normal;
  color: #4c5966;
  font-weight: 400;
}

.resume {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.resume p {
  font-style: normal;
  color: #828e99;
  font-weight: 400;
}

.resume p a {
  color: #828e99;
}

.dashjobicon p a {
  color: #4c5966;
}
.post-list {
  margin-top: 10px;
}

.post-container {
  background: white;
  padding: 18px 18px;
  box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
  border-radius: 8px;
  margin-top: 24px;
}

.analytics h2 {
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #000000;
  margin-bottom: 0px;
}

.analytics h2 a {
  color: black;
}
p {
  margin-left: 0px !important;
}

.mainpage {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 40px;
}

.resumecol {
  max-width: 350px;
  width: 100%;
  padding-top: 20px;
  padding-right: 40px;
  padding-left: 20px;
  background: white;
  border-radius: 8px;
}

.postcol {
  max-width: 530px;
  width: 100%;
}

.dashboardcol {
  max-width: 350px;
  width: 100%;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 40px;
  background: white;
  border-radius: 8px;
}

/*------------------------------------- First Column --------------- */
.favourite {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
}

.fvtresum,
.activejob {
  display: flex;
  align-items: center;
}

.fvtresumtxt,
.activejobtxt {
  margin-left: 15px;
}

.fvtresumtxt h6,
.activejobtxt h6 {
  color: #4c5966;
  margin-top: 10px;
  margin-bottom: 0px;
  line-height: 20px;
}

.fvtresumtxt h6 a,
.activejobtxt h6 a {
  color: #4c5966;
}

.fvtresumtxt p,
.activejobtxt p,
.posttxt p {
  font-weight: 400;
  margin-bottom: 0px;
  line-height: 20px;
  color: #828e99;
}

.postshare p {
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #828e99;
  padding-left: 11px;
}

.postshare p a {
  color: #828e99;
}

.fvtresumtxt p a,
.activejobtxt p a,
.posttxt p a {
  color: #828e99;
}

.creatpost p {
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 0px;
  color: #828e99;
}

.posttxt p:nth-child(1) {
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #242833;
}

.activejob {
  padding-top: 20px;
  width: fit-content;
}

.creatpost {
  background: white;
  padding: 12px;
  box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
  border-radius: 8px;

}

.showpost {
  background: white;
  padding: 18px 18px;
  box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
  border-radius: 8px;
  margin-top: 24px;
}

.mainpost img {
  /* max-width: 476px; */
  width: 100%;
  margin: auto;
}

.mainpost ul {
  padding-top: 10px;
  padding-left: 20px;
}

.mainpost p,
.mainpost ul li {
  font-style: normal;
  font-weight: 400;
  text-align: justify;
  line-height: 23px;
  margin-bottom: 0px;
  color: #4a4a4a;
}

.postprofile {
  display: flex;
  align-items: center;
}

.posttxt {
  padding-left: 13px;
}

.postdots {
  display: flex;
  justify-content: end;
  align-items: center;
}

.z-index-minus-one{
  z-index: -1;
}
.bt-toast .eirsPS button.btn-dismiss {
  font-size: 2.0rem !important;
}
.carousel-image{
  height: 300px;
  max-height: 300px;
  width: 100%;
  max-width: 800px;
  object-fit: contain;
  background: aliceblue;
  border-radius: 10px;
  /*object-fit: cover;*/
}
.social-media-full-image{
  /*height: 60vh !important;*/
  max-height: 60vh !important;
  align-content: center !important;
  max-width: 100% !important;
  border-radius: 10px;

}
.social-media-custom-modal .modal-content {
  background-color: aliceblue;
}

.social-media-custom-modal-header {
  background-color: var(--primary-color);
  color: white; /* Optional: For better visibility of text on blue background */
  padding: 25px; /* Reset default padding */

}
.social-media-close-icon {
  cursor: pointer;
  position: absolute;
  /*top: 0.5rem; !* Adjust for spacing as needed *!*/
  right: 0.5rem; /* Adjust for spacing as needed */
}



/* Example CSS */
.carousel .thumbs-wrapper {
  margin-top: 10px; /* Adjust margin top as needed */
}

.carousel .thumb {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%; /* Add rounded corners */
  /*margin-right: 10px; !* Adjust margin right as needed *!*/
}
.carousel .thumb img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50% !important;
}
.carousel .thumb.selected {
  border: 1px solid var(--secondary-color) !important; /* Border color for the selected thumbnail */
}

.postshare {
  display: flex;
  align-items: baseline;
  margin-right: 30px;
}

.postshare img {
  width: 20px;
}

#postcreat {
  display: none;
}

.posticons {
  display: flex;
  padding-top: 25px !important;
}

/*---------------------------------First Column ------------------------*/

.dashjobicon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashjobicon p:nth-child(2) {
  color: #003580;
  background-color: #f5f5f5;
  border: 1px solid #003580;
  text-align: center;
  line-height: 23px;
  padding: 4px;
  font-weight: 700;
  width: 33px;
  height: 33px;
  border-radius: 50%;
}

.dashjobicon p:nth-child(2) a {
  color: #003580;
}

/*------------------------------------ Second Column ------------------------------ */
textarea {
  width: 100%;
  border: none;
}

::placeholder {
  color: #4c5966;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 0px;
  color: #828e99;
}

.postmaterial {
  display: flex;
}

.postmaterial img {
  padding-right: 12px;
  width: 40px;
}

.postpublish p {
  margin-right: 13px;
}

.postpublish button {
  padding: 4px 20px;
  background: #003580;
  color: white;
  border: 1px solid #003580;
  border-radius: 4px;
}

/* ----------------------------------------------------------------- */
/*                         Post A JOb                               */
/* ----------------------------------------------------------------- */

.postcontainer {
  background-color: white;
  max-width: 60%;
  margin: auto;
  padding: 20px 30px;
}

h5 {
  position: relative;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: #17181a;
}

.fields {
  margin-top: 20px;
}

.formheading h5:after {
  /*content: "";*/
  display: block;
  max-width: 74%;
  width: 100%;
  height: 1px;
  background: #dbdbdb;
  left: 170px;
  top: 60%;
  position: absolute;
}

label {
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #4c5966;
}

input[type="text"] {
  width: 50%;
  padding: 8px 10px;
  border: 1px solid #dddddd;
  box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
  border-radius: 8px;

}
#posttype-cat {
  padding: 4px 10px;
  background: #003580;
  margin-right: 15px;
  color: white;
  border: 1px solid #003580;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.carousel .thumbs-wrapper {

margin-left: -20px !important;
}
/* Add this to your CSS */


h1,h2,h3,h4,h5{
  font-family: var(--font-family)  !important;
  font-weight: bold  !important;
}
span{
  font-family: var(--font-family)  !important;

}
p{
  font-family: var(--font-family)  !important;

}
label{
  font-weight: bold !important;
}
input[type=text] {
  font-family: var(--font-family)  !important;

}
.social-media-font {
  font-family: var(--social-media-font-family) !important;

}
.social-media-font-p {
  font-family: var(--social-media-font-family) !important;
  font-weight: lighter;
  font-size: 15px;
}
.main_image{
  width: 160px;
}
button{
  font-family: var(--font-family)  !important;
}
.text-success{
  font-family: var(--font-family)  !important;
}
.text-danger{
  font-family: var(--font-family)  !important;
}

body {
  font-family: var(--font-family)  !important;
}
.generic-image-size{
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .resumecol {
    max-width: 280px;
    padding-right: 20px;
  }

  .postcol {
    max-width: 520px;
  }

  .dashboardcol {
    max-width: 280px;
    padding-left: 20px;
  }
}

@media (max-width:1143px){
  .postcontainer{
    max-width: 95%;

  }
}
@media (max-width:584px) {
  .postcontainer{
    padding: 10px;
  }

}