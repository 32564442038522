@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600&family=Yantramanav:wght@100;300;400;500;700&display=swap');
@import "variables.css";
@import "fontSize.css";


.col-8.d-flex.justify-content-end.align-items-center{
    margin-left: -11px !important;
}
.favorite-jobs-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.favorite-job-item {
    display: flex;
    align-items: center;
    gap: 10px;
}

.favorite-job-item {
    display: flex;
    align-items: center;
}

.favorite-job-item img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
}
.img-style{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
    margin-bottom: 10px;

}
.personaldash-txt h6{
    color: var(--sidebar-heading-color) !important;
    margin-bottom: 0px;
    cursor: pointer;
}

.comments-divider {
    height: 1px;
    background-color: #ccc;
    margin: 10px 0;
}

.comments-section {
    margin-top: 10px;
}

.comment-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
}

.user-details {
    display: flex;
    align-items: flex-start;
}

.sm-small-img-style {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}
.dot_icon{
    color:#6e6d6d;
    cursor: pointer;
}
.dropdown_position{
    min-width: 120px;
    position: absolute;
    right: -8px;
}

.user-info {
    /*margin-left: 10px;*/
    background-color: #ededed; /* Off-white color */
    padding: 0 10px 10px 10px;
    border-radius: 8px;
    width: 100%;
}

.user-name {
    font-weight: bold;
    margin: 0;
    /*font-size: 1rem;*/
    color: #444;
}




.comment-time {
    font-size: 0.7rem;
    color: #888;
    /*margin: 5px 0;*/
}

.comment-text {
    margin: 1px 0;
    color: #4e4e4e
}

.custom-load-text{
    font-weight: 600;
    padding: 2px 6px 3px;
}
.custom-load-text:hover{
    background: #ededed;

}
.post-comment-container {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.post-comment-icon {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.comment-textarea {
    flex: 1 1;
    border-radius: 5px;
    resize: none;
    border: 1px solid #9d9595;
    outline: none;
    transition: border-color 0.2s;
    padding: 7px 0px 0px 10px;
    min-height: 38px;
    overflow-y: hidden;
}


.comment-input {
    flex: 1;
    display: flex;
    align-items: center;
}


.loading-icon {
    animation: spin 1s linear infinite;
    margin-right: 0.5rem;
}

.send-button .hidden {
    visibility: hidden;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


.send-button {
    background-color: #003580;
    border-color: #003580;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    margin-left: 10px;
}

.send-button.disabled {
    background-color: #ccc;
    color: #999;
    cursor: not-allowed;
}

.send-button .ban-circle {
    position: relative;
    top: -10px;
    left: 10px;
    color: red;
}



/* Custom CSS */
.custom-view-more-btn {
    border-color: #003580 !important;
    color:  white !important;
    background-color: #003580 !important;
}



.favorite-job-details {
    /*margin-left: 10px;*/
    color: var(--sidebar-heading-color);
}

.favorite-job-details h6 a
 {
    /* color: white; */
    color: var(--sidebar-heading-color) !important;
    text-decoration: none;
}
.favorite-job-details p a{
   color: var(--sidebar-text-color) !important;
    text-decoration: none; 
}
.favorite-job-details p , .favorite-job-details h6{
    margin-bottom: 0px;
}
.favorite-job-details{
    display: flex;
    flex-direction: column;
    /*row-gap: 3px;*/
}

.favorite-job-details button {
    width: fit-content;
    margin-top: 4px;
}

.js_container_div {
    position: relative;
    display: inline-block;
  }
  .js_dropdown {
    position: absolute;
    background-color: #fff;
    top: 41%;
    left: -74px;
    width: 105px;
    z-index: 2;
    border: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
  }
.dropdown-link:hover {
    cursor: pointer;
    width: 100%;
    width: 100%;

}

/*ul {*/
  /*  list-style: none;*/
  /*  padding: 0;*/
  /*  margin: 0;*/
  /*}*/
  /*li {*/
  /*  padding: 8px 12px;*/
  /*}*/

  /*li:hover {*/
  /*  background-color: var(--ck-color-base-active);*/
  /*  color: #fff;*/
  /*  cursor: pointer;*/
  /*}*/


.dashjobicon-rd p{

    font-style: normal;
    color: var(--sidebar-text-color);
    font-weight: 400;
}
.main-rd{
    background-color: #F5F5F5;
    /*height: 100vh;*/
    padding-top:73px
}
.resume-rd{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 16px;
}
.resume-rd p {
    font-style: normal;
    color: var(--sidebar-text-color);
    font-weight: 400;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.2rem;
}
.resume2-rd h2 , .resume-rd h2{
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    margin-bottom: 0px;
    color: var(--sidebar-heading-color);
}
.hr-style2 {
    height: 1px;
    margin-left: 5%;
    width: 95%;
    opacity: 1;
    border-top: 1px solid var(--divider-color);
}
.resume-rd h2 a{
    color: #fff;
}
.resume2-rd h2 a{
    color: #fff;
}
.resume2-rd{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*padding:16px 0px;*/
}
.circle{
    margin-top: 0px !important;
}
.circle h6{
    cursor: pointer !important;
}

.toggle-button-coverRD
{
    display: table-cell;
    position: relative;
    width: 75px;
    height:0px;
    box-sizing: border-box;
}

.button-coverRD:before
{
    counter-increment: button-counter;
    content: counter(button-counter);
    position: absolute;
    right: 0;
    bottom: 0;
    color: #d7e3e3;
    font-size: 12px;
    line-height: 1;
    padding: 5px;
}

.button-coverRD, .knobsRD, .layerRD
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.buttonRD
{
    position: relative;
    top: 50%;
    width: 74px;
    height: 36px;
    margin: -20px auto 0 auto;
    overflow: hidden;
}

.buttonRD.r, .buttonRD.r .layerRD
{
    border-radius: 100px;
}

.buttonRD.b2
{
    border-radius: 2px;
}

.checkboxRD
{
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
}
.toggle-button-coverRD .custom-control-label::before {
    width: 70px;
    height: 35px;
}

.form-switch .form-check-input {

width: 3em !important;
    height: 1.5em !important;
    border:1px solid #6e6d6d;
}

.knobsRD
{
    z-index: 2;
}

.layerRD
{
    width: 100%;
    background-color: #ebf7fc;
    transition: 0.3s ease all;
    z-index: 1;
}

/* Button 1 */
#button-1RD .knobsRD:before
{
    content: 'No';
    position: absolute;
    top: 4px;
    left: 4px;
    width: 29px;
    height: 29px;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    padding: 9px 4px;
    background-color: #003580;
    border-radius: 50%;
    transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}

#button-1RD .checkboxRD:checked + .knobsRD:before
{
    content: 'Yes';
    left: 42px;
    background-color: #003580;
}

#button-1RD .checkboxRD:checked ~ .layerRD
{
    background-color: #ffffff;
}

#button-1RD .knobsRD, #button-1RD .knobsRD:before, #button-1RD .layerRD
{
    transition: 0.3s ease all;
}

/* Button 2 */
#button-2RD .knobsRD:before, #button-2RD .knobsRD:after
{
    content: 'Yes';
    position: absolute;
    top: 4px;
    left: 4px;
    width: 20px;
    height: 10px;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    padding: 9px 4px;
    background-color: #003580;
    border-radius: 50%;
    transition: 0.3s ease all;
}

#button-2RD .knobsRD:before
{
    content: 'NO';
}

#button-2RD .knobsRD:after
{
    content: 'Yes';
}

#button-2RD .knobsRD:after
{
    right: -28px;
    left: auto;
    background-color: #F44336;
}

#button-2RD .checkboxRD:checked + .knobsRD:before
{
    left: -28px;
}

#button-2RD .checkboxRD:checked + .knobsRD:after
{
    right: 4px;
}

#button-2RD .checkboxRD:checked ~ .layerRD
{
    background-color: #ffffff;
}
.resume-rd p a{
    /* color:#ffffff; */
    color: var(--sidebar-text-color);
}
.dashjobicon-rd p a{
    /* color:#ffffff; */
    color: var(--sidebar-heading-color);
}
.analytics-rd h2{
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: var(--sidebar-heading-color);
}
.analytics-rd h2 a{
    /* color: #ffffff ; */
    color: var(--sidebar-heading-color);
}
 .analytics-rd .activejob-rd:nth-child(1){
    padding-top: 0px !important;
 }
.personalresume-rd{
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
}
.personalimg-rd img{
    width: 50px;
    height: 50px;
}
.personalresume-rd2{
    margin-top: 20px;
    margin-bottom: 17px;
}

.personaldash-txt{
    margin-left: 10px;
}
.personaldash-txt p{
    margin-bottom: 0px;
    color: var(--sidebar-text-color);
}
.personaldash-txt p:nth-child(1) a{
    /* color: rgb(255, 255, 255) !important; */
    color: var(--sidebar-text-color);
    font-weight: 600;
}
.personaldash-txt p a{
    /* color: #ffffff; */
    color: var(--sidebar-text-color);
}
.Jsdashtext{
    margin: 0;
}
.Jsdashtext span{
    font-weight: 600;
    color: var(--sidebar-heading-color);
}
.JStext, .Jsdashtext  {
   color: var(--sidebar-text-color);
}
.JSname{
 color: var(--sidebar-heading-color);
 cursor: pointer;   
}

.mainpage-rd{
    /* margin-right:132px; */
    display: flex;
    flex-direction: row;
    justify-content:space-between;

}

.resumecol-rd{
    max-width: 350px;
    width: 100%;
    padding: 20px 15px 40px 15px;
    border-radius: 8px;
    height: 85vh;
    overflow-y: auto;
    /* background: #003580; */
     /* border-top: 8px solid #e54545; */
     background: var(--sidebar-bg);
     border-top: 8px solid var(--sidebar-border-color);
     box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
   
    
}
.postcol-rd{
    max-width: 530px ;
    width: 100%;
    margin: 0px 20px;


}
.dashboardcol-rd{
    max-width: 350px;
    width: 100%;
    padding: 20px 15px 40px 15px;
    border-radius: 8px;
    height: 85vh;
    overflow-y: auto;

    border-top: 8px solid var(--sidebar-border-color);
    background: var(--sidebar-bg);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
/*------------------------------------- First Column --------------- */
.favourite-rd{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
}
.fvtresum-rd, .activejob-rd{
    display: flex;
    align-items: center;
}
.fvtresum-rdtxt ,.activejob-rdtxt {
    margin-left: 15px;
}
.fvtresum-rdtxt h6 ,.activejob-rdtxt h6 {

    color: var(--sidebar-heading-color);
    /*margin-top: 10px;*/
    margin-bottom: 0px;
    line-height: 20px;
    cursor: pointer;
}

.fvtresum-rdtxt p, .activejob-rdtxt p , .posttxt-rd p{
    font-weight: 400;
    margin-bottom: 0px;
    line-height: 20px;
    color: white;
    
}
.activejob-rdtxt p{
    color: var(--sidebar-text-color);
}

.basic_text{
    color: var(--sidebar-text-color);
}

.postshare-rd p{
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    color: #828E99;
    padding-left: 11px;
    margin: 0;
}
.postshare-rd p a{
    color: #828E99;
}
.fvtresum-rdtxt p a , .activejob-rdtxt p a , .posttxt-rd p a{
    color: #fff;
}
h2{
    color: #fff;
}
.creatpost-rd p{
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 0px;
    color: #828E99;
}
.posttxt-rd p:nth-child(1){
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    color: #242833;
}

.activejob-rd{
    /*padding-top: 20px;*/
    width: fit-content;
}

.activejob-rd img{
    width: 56px;
    height: 56px;
    border-radius: 50%;
}

.div_logo{
    height: 56px;
    width: 56px;
    border-radius: 50%;
    background: #e54545;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}
.div_logo h4{
    margin: 0;
    letter-spacing: 1px;
}


.creatpost-rd{
    background: white;
    padding: 18px 18px;
    box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
    border-radius: 8px;
}

.showpost-content{
    background: white;
    padding: 1rem;
    box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
    border-radius: 8px;
    margin-top: 1rem;
}

.mainpost-rd img{
    /* max-width: 476px; */
    max-width: 100%;
    margin: auto;

}
.mainpost-rd ul {
    padding-top: 10px;
    padding-left: 20px;
}
.mainpost-rd p,.mainpost-rd ul li {

    font-style: normal;
    font-weight: 400;
    text-align: justify;

    line-height: 23px;
    margin-bottom: 0px;
    color: #4a4a4a;
}
.postprofile-rd{
    display: flex;
    align-items: center;
}
.posttxt-rd{
    padding-left: 13px;
}

.postdots-rd{
    display: flex;
    justify-content: end;
    align-items: center;
}


.postshare-rd{
    display: flex;
    align-items:baseline;
    margin-right: 30px;
}
.postshare-rd img{
    width: 20px;
}

#postcreat{
    display: none;
}

.posticons-rd{
    display: flex;
    padding-top: 25px !important;


}
/*---------------------------------First Column ------------------------*/

.dashjobicon-rd{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dashjobicon-rd:nth-child(3){
    margin-bottom: 16px;
}
.dashjobicon-rd p:nth-child(2){
    color: #000;
    background-color: white;
    /* border: 1px solid #003580; */
    border: 1px solid var(--sidebar-text-color);
    text-align: center;
    line-height: 23px;
    padding: 5px;
    font-weight: 700;
    width: 35px;
    height: 35px;
    border-radius: 8px;
}
.dashjobicon-rd p:nth-child(2) a{
    /* color: #003580; */
    color: black;
}

/*------------------------------------ Second Column ------------------------------ */
.js-dashboard-posts-section{
    height: calc(100vh - 180px);
    overflow-y: auto;
    margin: 10px 0 10px 0;
}
.js-dashboard-posts-section::-webkit-scrollbar {
    width: 0;
}
.resumecol-rd::-webkit-scrollbar {
    width: 0;
}
.dashboardcol-rd::-webkit-scrollbar {
    width: 0;
}
.text-color{
    color: var(--sidebar-text-color);
}
textarea{
    width: 100%;
    border: none;
}
::placeholder{
    color: #4C5966;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 0px;
    color: #828E99;
}

.postmaterial-rd{
    display: flex;
}
.postmaterial-rd img{
    padding-right: 12px;
    width: 40px;
}
.postpublish-rd p{
    margin-right: 13px;
}
.postpublish-rd button{
    padding: 4px 20px;
    background: #003580;
    color: white;
    border: 1px solid #003580;
    border-radius: 4px;
}
hr.jslinebreak{
    color: white;
    opacity:unset ;
}
.post-image {
    max-width: 100%;
    height: auto;
}
.profile-image{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
    margin-bottom: 10px;
}

@media (min-width:1200px) and (max-width:1400px){

    .resumecol-rd{
          max-width: 290px;
        padding: 20px 15px 40px 15px;
        width: 100%;

    }
    .postcol-rd{
        max-width: 500px;
        width: 100%;
    }
    .dashboardcol-rd{
        max-width: 290px;
        padding: 20px 15px 40px 15px;
        width: 100%;
    }
}

/*------------------------MOBILE AND TABLET ---------------------*/
@media (max-width: 1024px){
    .dashboardcol-rd,
    .resumecol-rd{
        display: none;
    }
    .postcol-rd{
        max-width: 100%;
        margin: 0;
    }
    .mainpage-rd{
    max-width: 100%;
    }


}