@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

*{
    padding: 0px;
    margin: 0px;
}

p, a, label, small, span, button, input,
h1, h2, h3, h4, h5, h6, li, i, td, th{
    font-family: var(--font-family) !important;
}

p, a, label, span, h5, input,
h6, li, button,  i, td, th{
    font-size: 0.9rem !important;
}
h2{
    font-size: 1.2rem !important;
}
h3{
    font-size: 1.1rem !important;
}
h4{
    font-size: 1rem !important;
}

small{
    font-size: 0.7rem !important;
}
a {
    text-decoration: none !important;
    background-color: none;
}

p{
    color: #545353;
}

.row>*{
    padding: 0px !important;
}

::placeholder {
    color: #4C5966;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 0px;
    color: #828E99;
    font-family: var(--font-family) !important;
    font-size: 0.9rem !important;
}

.heading{
    color: #003580 !important;
    /*text-align: center !important;*/
    /*margin: 0px !important;*/
    /*text-transform: uppercase;;*/
}

.blue_content_bold{
    color: #003580 !important;
    font-weight: 700;
}
.blue_color{
    color: #003580 !important;
}
