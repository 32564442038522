@import url('https://fonts.googleapis.com/css2?family=Comic+Neue&family=Hind&family=Karla&family=Lato&family=Merriweather&family=Montserrat&family=Mulish&family=Nunito+Sans:opsz@6..12&family=Open+Sans&family=Poppins&family=Quicksand&family=Raleway&family=Roboto:ital@0;1&family=Work+Sans:ital@1&display=swap');
  :root {

     --sidebar-bg: #003580;
     --sidebar-heading-color: #fff;
     --sidebar-text-color: #fff;
     --sidebar-border-color: #e54545;
     --sidebar-primary-color: #003580;
     --divider-color:#fff;

     --admin-background-color:#000;

     --font-black-color:#000;
     --font-white-color:#fff;

     --bg-color:#003580;
     --bg-white-color:#fff;
     --bg-light-gray: #0000000d;
     /*--primary-color:#1DA1F2 ;*/
     --primary-color:#003580 ;
     --landing-primary-color:#1DA1F2 ;

     --secondary-color:#e54545 ;
     --third-color:#60BF9F;

     --btn-primary-color:#003580;
     --btn-secondary-color:#e54545;
     --btn-success-color:#60BF9F;

     --highlight-color: rgba(255, 235, 59,0.5);

     /*--font-family: 'Arial, Helvetica, sans-serif'; ! Font family 1 !*/
     /*--font-family: 'Roboto'; ! Font family 2 !*/
     /*--font-family: 'Open Sans'; ! Font family 3 !*/
     /*--font-family: 'Lato'; ! Font family 4 !*/
     /*--font-family: 'Montserrat'; ! Font family 5 !*/
     /*--font-family: 'Poppins'; ! Font family 6 !*/
     --font-family: 'Raleway';
     /*--font-family: 'Nunito'; ! Font family 8 !*/


     /*--social-media-font-family: 'Poppins'; ! Font family 6 !*/
     /*--social-media-font-family: 'Quicksand'; ! Font family 6 !*/
     /*--social-media-font-family: 'Muli'; ! Font family 6 !*/
     /*--social-media-font-family: 'Karla'; ! Font family 6 !*/
     /*--social-media-font-family: 'Work Sans'; ! Font family 6 !*/
      --social-media-font-family: 'Raleway';
     /*--social-media-font-family: 'Arial'; ! Font family 6 !*/

  }

  /*:root {*/
  /*    --sidebar-bg: #ededed;*/
  /*    --sidebar-heading-color: #000;*/
  /*    --sidebar-text-color: #1c1c1c;*/
  /*    --sidebar-border-color: #003580;*/
  /*    --sidebar-primary-color: #000;*/
  /*     --divider-color:#000;*/
  /*}*/