@import "variables.css";
@import "fontSize.css";

.Profile_Container {
  width: 100%;
  padding-top: 73px;
  background-color: #f5f6f7;
}
.main_pv_container {
  width: 80%;
  margin: 0 auto;
}

.pv_top_header {
  position: relative;
}

.profile_backgound {
  width: 100%;
}
.disabled_button {

  opacity: 0.5;
  cursor: not-allowed;
}

.disabled_button:hover {
  opacity: 0.6;
}


.profile_top_content {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: end;
  object-fit: cover;
  width: 100%;
  height: 150px;
}

.profile_top_left_content {
    position: absolute;
    bottom: 0px;
    left: 3px;
}
.profile_top_left_content img{
  width: 140px;
  height: 140px;
  border-radius: 10px 10px 10px 10px;
  object-fit: cover;

}

.profile_top_right_content {
    display: flex;
    flex-direction: row;
    /* column-gap: 1.3rem; */
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: white;
    padding: 15px 15px;
    margin-top: -3px;
    margin-right: 2px;
    margin-left: 6px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

}
.profile_name_heading h4{
  padding-left: 190px;
}
.profile_icons{
  display: flex;
  column-gap: 14px;
}

.profile_top_right_content button {
  padding: 5px 10px;
  color: #fff;
  background-color: #003580;
  /*text-transform: uppercase;*/
  border: 1px solid #003580;
  border-radius: 4px;
}

.pv_below_content {
  margin-top: 1rem !important;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  column-gap: 1.2rem;
}
.pv_left_below {
  width: 40%;
  display: flex;
  flex-direction: column;

}

.pv_left_detail {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
}
.pv_left_detail p{
  margin-bottom: 0px;
  font-weight: 500;
}
.pv_left_detail_desc {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.pv_left_detail_desc p{
  color: #4b4b4b;
  font-weight: 400;
  margin-bottom: 0px;
}
.pv_left_detail_content1_section {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  margin-bottom: 10px;
}
.pv_left_detail_content1_section p{
  color: black;
  font-weight: 600;

}
.pv_left_detail_content1_section span{
  color: #4b4b4b;
  font-weight: 400;

}
.pv_blue_content {
  color: #003580;
}
.custom_follower_content{
  width: 100%;
  border-bottom: 1px solid var(--sidebar-text-color);
}
.custom_follower_content:first-child{
  border-top: 1px solid var(--sidebar-text-color);

}
.custom_follower_content:last-child{
  border-bottom: none;

}

.follower_main_content {
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 0.6rem;
}

.follower_content {
  height: 150px;

  border-radius: 10px;
  width: 33%;
}

.follower_content img {
  width: 100%;
  border-radius: 10px;
}
.follower_content h5 {
  color: #003580;
  padding: 10px 0px;
}
.user_info_detail{
  background: white;
  padding: 10px;
  box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.follower_img{
  width: 56px;
  height: 56px;
  border-radius: 56%;
  object-fit: cover;
}

.pv_right_below {
  width: 60%;
}
.follower_heading{
  color: var(--sidebar-heading-color);
}
.follower_name{
  color: var(--sidebar-heading-color);
}
.follower_des{
  color: var(--sidebar-text-color);
}
@media (max-width: 1024px){

  .pv_below_content{
    flex-direction: column;
  }
  .pv_right_below{
    width: 100%;
  }
  .pv_left_below{
    width: 100%;
  }
.profile_top_left_content {
    position: absolute;
    bottom: 0px;
    left: 0px;
}
}

@media (max-width: 768px){
  .main_pv_container{
    width: 95%;
  }
  .profile_top_left_content img{
  width: 90px;
  height: 90px;
}
.profile_name_heading h4  {
    padding-left: 90px;
}
}