/* Add this CSS to your component's CSS file or a global CSS file */
.mc-custom-tab {
    background-color: #003580 !important;
    color: white !important;
}
/* mycircles.css */

.tabs_custom{
    position: relative !important;
    z-index: 1 !important;
}
/* Define the custom class for the Follow button */
.btn-outline-custom {
    color: #003580;
    border-color: #003580;
    width: 7rem;
}

/* Style the button on hover */
.btn-outline-custom:hover {
    background-color: #003580;
    color: white;
}
/* Add these custom CSS classes */
.followers-list {
    z-index: 1040 !important; /* Set your desired z-index value */
    width: auto !important; /* Set your desired width value */
    padding-bottom: 55px;
}

.followings-list {
    z-index: 1040 !important; /* Set your desired z-index value */
    width: auto !important; /* Set your desired width value */
    padding-bottom: 55px
}

