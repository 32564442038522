@import "fontSize.css";

.CRmainpreview{
    padding-top: 73px;
    padding-bottom: 40px;
    background: #F5F6F7;
    overflow-x: hidden;
    padding-left: 40px;
    padding-right: 40px;
}
.CRpostpreviewcontainer{
    background-color: white;

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 0 8px 24px #00000012;
    border-radius: 8px;
    max-width: 100%;
    /* margin: auto; */
    /*padding: 40px 80px;*/
}
.RP-head{
    border: 1px solid #003580;
    background-color: #003580;
    padding: 0.5rem;
    margin-top: 1rem;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.RP-head p , .RP-head label {
    color:white !important;
}
.profile-imageRP{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    
}
.RDheadtitle{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 8px;
    border-radius: 6px;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    width: 100%;
}
.fvthead {
    margin-bottom: 0.6rem;
}
.RDheadtitle h2{
    line-height: 24px !important;
    color: #003580 !important;
    font-weight: 500;
    text-align: center;
    margin-bottom: 0px !important;
    text-transform: capitalize;
}
.backbtnRD button{
    border: none;
    background: none;
    color: #003580;
    margin-bottom: 10px;

}
.IconsRD img{
    width: 22px;
    height: 22px;
}
.CR-job-title{

    font-weight: 500 !important;
}
.active_for_job {
    border: 1px solid #e54545;
    background-color: #e54545;
    margin-top: 17px;
    text-align: left;
    padding: 2px 20px;
    border-radius: 6px;

}
.CRpdf{
    padding: 10px 10px;
}
.CRallsection{
    padding: 30px 40px;
    width: 100%;
}
.highlight {
    background-color: var(--highlight-color);
    font-weight: bold;
}
/* Add this to your CSS stylesheet */
.custom-half-width-modal {
    max-width: 60%; /* Adjust the width as needed */

}

.CRpostpreviewcontainer h2{
    margin-bottom: 7px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    color: #fff;
}
.CRpostpreviewcontainer h3 , .CRjob-desc h3{
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    padding-top: 10px;

    padding-bottom: 15px;
}
.CRpostpreviewcontainer h3{
    color: #4C5966;
}
.CRjob-desc h3{
    color: #000000;
}
label {
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    color: #828E99;
}

.CRjobpreview p{
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    color: #17181A;
    margin-bottom: 0px;
}

.CRjobpreview1 p{
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    color: #17181A;
    margin-bottom: 0px !important;
}

.CRgeneralpreview{
    padding: 0 0.5rem;
    border-radius: 8px;
}
.CRdatepreview,.CRjobdescpreview{
    margin-bottom: 10px;
}
.CRjobpreview-screen{
    padding-top: 0.2rem;
}
.CRjobpreview-screen h4{
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    color: #003580;
}
.CRjobpreview-title{
    padding-top: 15px;
}
.CRrespreview{
    display: flex;
    justify-content: space-between;
}
.CRrespreview p{
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    color: #17181A;
    margin-bottom: 0px;
}
.CRresumeicon{
    display: flex;
    justify-content: start;
    align-items: center;
    column-gap: 10px;
}
.CRjobpreview {
    display: flex;
    align-items: baseline;
}

.CRjobpreview1 {
    display: flex;
    align-items: baseline;
    flex-direction: column;

}

.CRjob-p1 , .CRjob-p2 , .CRjob-p3, .CRjob-p4{
    display: flex;
    align-items: baseline;
}
.CRjob-p2 ,.CRjob-p3, .CRjob-p4{
    margin-left: 20px;
}
.CRjob-desc{
    padding: 20px 0px;
}
.CRjob-desc textarea {
    width: 100%;
    height: 250px;
    background: #F5F6FA;
    box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
    border-radius: 8px;
    border: none;
    line-height: 28px;
    padding: 15px 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: justify;
}
.CRlabelcol{
    font-weight: 900;

}
.CRjobid p{
    padding-left: 15px;
}
.CRjob-btn{
    display: flex;
    justify-content: end;
    padding: 20px 0px;
    
}
.CRjob-btn button{
    padding: 10px 30px;
    background: #fff;
    border: 1px solid #003580;
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #003580;
}
.rectangel-image{
    width: 5%;
    height:5px;
    position: absolute;
    top: -2px;
    left: 0;
}
.rectangel-image2{
     width: 5%;
    height:5px;
    position: absolute;
    top: 14px;
    left: 0;
}
.hr-style{
    height: 1px;
    margin-left: 5%;
    width: 95%;
}
.custom-hr {
    margin-top: 0px;
    width: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-top: 8px solid #E54545;
    background: #E54545;
    opacity: 1;
}


.CRjob-post :nth-child(1){
    border: 1px solid #60BF9F;
    color: #60BF9F;
    margin-right: 14px;
}
.CRjob-post :nth-child(2){
    border: 1px solid #003580;
    color: white;
    background: #003580;
}



@media (max-width:550px){

    .user-content{
        flex-direction: column !important;
        gap: 16px;
    }
}