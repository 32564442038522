@import "variables.css";

.user_info{
    background-color: var(--sidebar-bg);
    width: 30%;
    padding: 10px;
    max-height: 300px;
    border-radius: 8px;

}
.user_info p{
    color: var(--sidebar-text-color);
    margin: 0;
}

.rightContent{
    background-color: #ededed;
    border-radius: 8px;
    width: 70%;
    margin-bottom: 3.5rem;
}
.notification_content{
    width: calc(100% - 50px);
}

.rightContent_scroll{
    height: calc(100vh - 175px);
    overflow-y: scroll;
}

.rightContent_scroll::-webkit-scrollbar {
    width: 0;
}

@media (max-width: 1024px) {

    .rightContent{
        width: 100%;
    }
    .user_info{
        display: none;
    }
}