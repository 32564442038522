@import "fontSize.css";

.main-CP{
    padding-top: 73px;
    background-color: #F5F6F7;
}
.mainHeading-CP{
    max-width: 60%;
    margin: auto;
    padding-bottom: 1rem;
    justify-content: space-between;
    align-items: center;
}
.mainHeading-CP h2{
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}
.profiledate-CP{
    display: flex;
    padding-top: 1rem;

}
.profiledate-CP p{
    color: #828E99;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 0px;
}
.profiledate-CP span{
    color: #010101;
    padding-left: 15px;
}
.postcontainer-CP{
    background-color: white;
    max-width: 60%;
    margin: auto;
    padding: 40px 70px; 
    border-top: 8px solid #E54545;
    border-radius: 6px;
}
/* ---------------------------- Personal Information -----------------------*/

.profilemaindata-CP{
    display: flex;
    justify-content: space-between;
    gap: 1.2rem;
    margin-top: 20px;
}
.Prophoto-CP{
    border: 1px solid #a7a7a7;
    text-align: center;
    width: 40%;
    height: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
}
.Prophoto-CP P{
    color: #010101;
    margin: 0px;
    font-weight: 700;

}
.Prodata-CP{
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    width: 80%;
}
.Proname-CP{
    display: flex;
    gap: 0.9rem;
}
.savebtn-CP{
    display: flex;
    flex-direction: row;
    column-gap: 6px;
}
.Prodata-CP textarea{
    width: 100%;
    padding: 10px 16px;
    border: 1px solid rgba(46, 46, 46, 0.5);
    box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
    border-radius: 8px;
}
.Profile-Content-IW{
    width: 100%;
}
.savebtn-CP button{
    background: #003580;
    border: 1px solid #003580;
    color: white;
    border-radius: 8px;
    padding: 6px 16px;
}

.expsection-CP,.institute-CP ,.dateselect-CP,.degreemajor-CP,.addbtn-CP,.savebtn-CP button{
    margin-top: 24px;
}
.dateselect-CP label{
    padding-bottom: 10px;
}
.degmajor-CP,.strdat-CP, .enddat-CP{
    width: 48%;
}
.degreemajor-CP,.dateselect-CP,.expdtl3-CP{
    display: flex;
    justify-content: space-between;
}
.expsection-CP{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.expheading-CP{
  display: flex;
    align-items: center;
    column-gap: 10px;
}
.expheading-CP h5{
    font-style: normal;
    font-weight: 500;
    margin: 0px;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: #003580;
}
/* ------------------------Contact section --------------------------------- */

.location-CP span,.expdtl2-CP span,.expdtl3-CP span,.expdtl1-CP span{
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #030303;
    margin-bottom: 0px;
    text-align: justify; 
    padding-left:6px;
}

.addanother-CP button{
    background: none;
    border: none;
}
.addbtn-CP button{
    padding: 4px 20px;
    background: #60BF9F;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 8px rgba(80, 121, 178, 0.1);
    border-radius: 50px;
    color: white;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}
.startdate-CP,.enddate-CP,.degree-CP,.major-CP {
    width: 100%;
    padding: 10px 16px;
    border: 1px solid rgba(46, 46, 46, 0.5);
    box-shadow: 0px 1px 4px rgba(115, 153, 191, 0.05);
    border-radius: 8px;
}
.startdate, .enddat-CP{
    display: flex;
    flex-direction: column;
}
.expdtl-CP{
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 8px rgba(80, 121, 178, 0.1);
    border-radius: 8px;
    padding: 15px 20px;
    margin-top: 1rem;
}
.expdtl2-CP p,.expdtl3-CP p,.expdtl1-CP p ,.expdtl4-CP p,.mobilenum-CP p,.location-CP p{
    font-style: normal;
    font-weight: 500;
    line-height: 1.5;
    color: #4C5966;
    margin-bottom: 0px;

}
.expdtl1-CP h4{
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}
.hobbieimg img{
    width: 27px;
    height: 27px;
}
.profileimg-CP img{
    width: 100px;
    height: 100px;
}
.expdtl-img-CP{
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: space-around;
}
.expdtl-img-CP img{
    width: 28px;
}
.addanother-CP img{
    width: 25px;
    height: 25px;
}



label{
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: black;
}


.crudbtn-CP {
    display: flex;
    justify-content: end;
}
.crudbtn-CP button{
    border: 1px solid #003580;
    color: #003580;
    padding: 4px 15px;
    border-radius: 4px;
}

@media (max-width: 1300px){
    .mainHeading-CP{
        max-width: 95%;
    }
    .postcontainer-CP{
        max-width: 95%;
    }
}
@media (max-width: 1024px){
    .profilemaindata-CP{
        align-items: center;
    }
    .Proname-CP{
       flex-direction: column;

    }

}
@media (max-width: 768px){
    .postcontainer-CP{
       padding: 0 0.6rem 0.6rem 0.6rem;
        margin-bottom: 3rem;

    }

}

@media (max-width: 600px){

    .profilemaindata-CP{
        flex-direction: column;
    }

    .Prodata-CP{
        width: 100%;
    }
    .degreemajor-CP, .dateselect-CP, .expdtl3-CP{
        flex-direction: column;
        gap: 1.2rem;
    }
    .degmajor-CP, .strdat-CP, .enddat-CP{
        width: 100%;
    }
    .profiledate-CP{
        flex-direction: column;
        gap: 0.5rem;
    }

}

