@import "fontSize.css";

.JBmain{
    padding-top: 73px;
}
.JBmain table{
    width: 100%;
}
.JAbackbtn button{
    border: none;
    background: none;
    color: #003580;
    margin-bottom: 10px;
}
.AJtitle2 {

    background-color: white;
    border: 1px solid #003580;
    border-radius: 4px;
    margin-bottom: 17px;
    padding: 8px 0;
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    
}

.AJtitle2 h2 {
    color: #003580 !important;
    margin: 0!important;
    text-align: right !important;
    /*text-transform: uppercase;*/
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    
}
.AJtitle2 p{
  color: #003580 ;
  text-align: end; 
  margin-bottom: 0; 
  margin-right: 10px;
  cursor: pointer;
}
.JBmain td {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 12px;
    text-wrap: nowrap;
}
.JA-viewresume{
    background: rgb(9, 130, 20) !important;
    border: 1px solid rgb(9, 130, 20) !important;
    color: rgb(255, 255, 255);
    border-radius: 4px;
    width: fit-content;
    padding: 3px 6px;
    cursor: pointer;
}

.JBmain th{
    white-space: nowrap;
}

.JBmain thead tr{
border: none !important;
}
.JBmain th:first-child{
   border-radius: 8px 0px 0px 0px; 
}
.JBmain th:last-child {
border-radius: 0px 8px 0px 0px;
text-align: center;
}
.JBmain thead tr{
    border-radius:8px 8px !important;
    border: none;
}
.JBmain tr:nth-child(8){
border-radius:8px ;
}
.JBmain tr{
    border-radius:8px ;
    vertical-align: middle;
}
.JBmain td:nth-child(1){
  border-radius:8px ;  
}
.JBmain th{
    background: #003580;
    color: white;
}
.JBmain tr{
    border: 1px solid #d8d8d8;
 
}
.JBmain th{
    /*text-transform: uppercase;*/
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    padding: 12px;
}
.JBmain tr:nth-child(odd) {
background-color: #f3f3f3;
}
.JBmain #job-action
{
    background: #003580;
}
.AJstatus_btn{
    background: #fff;
    color: #003580;
    padding: 3px 6px;
    border: 1px solid #003580;
    border-radius: 4px;
    width: fit-content;
    white-space: nowrap;
}
#job-action {
    color: black; 
    padding: 4px 3px;
    border-radius: 4px;
}
.table>:not(caption)>*>*{
    border-bottom: none !important;
    /*padding: 0 !important;*/
    /*background-color: none !important;*/
    box-shadow: none !important;
}
#job-action option {
    background-color: white; 
    color: black; 
}

.comment-expandable {
    max-height: 46px;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
}
.comment-expanded {
    max-height: 180px; /* Adjust this value based on your needs */
}
.scrollable-container {
    max-height: 300px; /* Adjust this value as needed */
    overflow-y: auto;
}

@media (max-width:575px){
    .postcol1aljob{
        padding-left: 20px;
        padding-right: 20px;
    }
.JBmain td{
    line-height: 15px;
}
.JBmain th{
    line-height: 16px;
}

}

