@import "fontSize.css";

.main {
  background-color: #f5f5f5;
  padding-bottom: 50px;
}
.nav {
  position: fixed;
  width: 100%;
  z-index: 50;
  box-shadow: #cbcbcb52 0px 8px 24px;

}

/*--------------------------------------Nav Bar -------------------------- */
.postbtn {
  display: flex;
  align-items: center;
}

.postbtn button a {
  text-decoration: none;
  color: white;
}

.navpad {
  padding: 10px 20px;
}

.header-icon img {
  /* margin-right: 20px; */
  width: 22px;
}

.postbtn .resume-ser,
.postbtn .resume-ser2 {

  border-radius: 50px;
  padding: 8px 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  width: 200px;
}
.postbtn .resume-ser2 {
  border: 1px solid #003580;
  background-color: #003580;
}

.postbtn button a {
  color: white;
}
.resume-ser a {
  color: white !important;
}
.postbtn .resume-ser {
  border-color: #e54545 !important;
  background-color: #e54545;
}

.dropdown-item:active{
  background-color: #003580;

}
.dropdown-item p{
  margin: 0;
}
.dropdown-item:active p,
.dropdown-item:active svg{
  color: #fff;
}
.icon_color{
  color: #545454;
}
.icon_color:active{
  color: #fff !important;
  /*cursor:pointer;*/
}

.mobileMenu hr{
  margin: 0;
}

.menu_content{
  display: flex;
  gap: 2rem;
  align-items: center;
  width: 100%;
  padding: 0.7rem;
  cursor: pointer;
}
.menu_content p{
  margin: 0;
}
.menu_content:hover{
  background-color: #e9ecef;
}
.menu_content:active{
  background-color: #003580;
  color: #fff;
}
.menu_content:active p{
  color: #fff;
}

.notification_content_unread{
  background-color: #378fe933;
}
.notification_content_read{
  background-color: #fff;
}
.notification_hover:hover{
  background-color: #e9ecef;
  color: #1e2125;
  cursor: pointer;
}
.user_logo_img{
  width: 45px !important;
  height: 45px !important;
  border-radius: 50%;
}

.currentuser_logo_img{
  width: 90px !important;
  height: 90px !important;
  border-radius: 50%;
}

.div_notf_logo{
  height: 45px;
  width: 45px;
  border-radius: 50%;
  background: #e54545;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.online_user_logo{
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #e54545;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: end;
  /*box-shadow: 0px 0px 5px rgba(128, 128, 128, 0.5);*/
}
.online_user_container{
  position: relative;
  display: inline-block;
}

.active_dot {
  position: absolute;
  top: 23px;
  right: 0;
  width: 10px;
  height: 10px;
  background-color: green;
  border-radius: 50%;
}

.div_notf_logo h4{
  margin: 0;
  letter-spacing: 1px;
}


.div_user_logo{
  height: 56px;
  width: 56px;
  border-radius: 50%;
  background: #e54545;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.div_user_logo h4{
  margin: 0;
  letter-spacing: 1px;
}



.icon-container {
  position: relative;
  display: inline-block;
  padding-right: 0.9rem;
}
.icon-container1 {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

}
.number-badge1 {
  position: absolute;
  right: -4px;
  top: -3px;
  background-color: #e54545;
  color: white;
  border-radius: 50%;
  width: 8px;
  height: 8px;

}

.number-badge {
  position: absolute;
  top: -9px;
  right: 7px;
  background-color: #e54545;
  color: white;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.customizeModal>.modal-dialog>.modal-content{
  position: absolute !important;
  width: 70%;
  right: 0;
}
