.main {
    background-color: #F5F5F5;
    padding-bottom: 50px;
}
.employnav{
    position: fixed;
    width: 100vw;
    z-index: 9999;
    box-shadow: #cbcbcb52 0px 8px 24px;
     padding: 20px 30px;
}

/*--------------------------------------Nav Bar -------------------------- */
.mobilenavpad{
    display: flex;
    justify-content: space-between;
}
.mobileheader-icon{
    display: flex;
}
.mobileheader-icon img {
    width: 22px;
}







@media (min-width:767px){
    .employnav{
        display: none;
    }
}